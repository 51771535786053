const Main = () => {
  return (
    <main className="flex-1 flex justify-center items-center">
      <p className="my-6 text-center text-zinc-700 text-2xl w-2/4">
        Hey everyone, Noleyo is an application where you connect with your activity peers! <br></br>
        <br></br> App is currently under development. Stay tuned for more updates! 🚀 <br></br>
        <br></br>
        Can’t wait to see you soon! 🎉
      </p>
    </main>
  );
};

export default Main;
