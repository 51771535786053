const Contact = () => {
  return (
    <div className="flex-1">
      <h1 className="text-4xl font-ds-400 ml-6">Contact</h1>
      <div className="ml-6">
        <p className="text-xl text-zinc-900">
          If you have any questions or suggestions, please feel free to contact me.
        </p>
        <p className="text-xl text-zinc-900">Email: support@noleyo.com</p>
        <p className="text-xl text-zinc-900">Instagram: Soon</p>
        <p className="text-xl text-zinc-900">Twitter: Soon</p>
      </div>
    </div>
  );
};

export default Contact;
