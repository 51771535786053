const About = () => {
  return (
    <>
      <div className="flex-1">
        <h1 className="text-4xl font-ds-400 ml-6">About</h1>
        <div className="flex justify-center items-center">
          <div className="w-2/3 text-xl text-center text-zinc-700 mb-6">
            Hey there
            <br /> Last year during my finals I was bored with exams and I was reading a book, after
            I finished the book I wanted to share my thoughts with someone who was reading the same
            book at that moment or who had just finished the book, but there was no one to talk to.
            <br />
            <br /> So I thought that maybe I could build an application that connects people who are
            currently in the same book, film or tv show and allows them to share their instant
            thoughts.
            <br />
            <br /> This application is product of this. It is where you add your current reading or
            watching activity and join chat rooms with people who are on same activity or exploring
            new activities.
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
