import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-red-500 flex justify-center items-center">
      <span className="font-ds-700 text-white" style={{ fontSize: "3rem" }}>
        <Link to={"/"}>Noleyo</Link>
      </span>
    </header>
  );
};

export default Header;
